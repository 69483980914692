import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { auth } from "./Helpers/Firebase";
import IdleTimer from "./Helpers/IdleTimer";
import { Suspense } from "react";
import FinancialPlanningTool from "./FinancialPlanningTool/FinancialPlanningTool";
import VolatilityGame2 from "./VolatilityGame2/VolatilityGame2";
import Riddles from "./Riddles/Riddles";
import FinancialplanningToolbackend from "./FinancialPlanningTool/FinancialplanningToolbackend";
import Howtoruninapple from "./Howtoruninapple/Howtoruninapple";
import UserProfileEditor from "./UserProfile/UserProfileEditor";
import Members from "./Members/Members";
import PaidCourses from "./PaidCourses/PaidCourses";
import Extras from "./Extras/Extras";
import ExcelCalculators from "./ExcelCalculators/ExcelCalculators";

const Dashboard = React.lazy(() => import("./Dashboard/Dashboard"));
const Monthlyreview = React.lazy(() => import("./Monthlyreview/Monthlyreview"));
const Monthlyreview2 = React.lazy(() =>
  import("./Monthlyreview2/Monthlyreview2")
);
const Mutualfundspick = React.lazy(() =>
  import("./Mutualfundspick/Mutualfundspick")
);
const Login = React.lazy(() => import("./Login/Login"));
const Resources = React.lazy(() => import("./Resources/Resources"));
const Mutualfunds = React.lazy(() => import("./Mutualfunds/Mutualfunds"));
const Mutualfundsnew = React.lazy(() => import("./MF2/MainView"));
const AssetAllocation = React.lazy(() =>
  import("./AssetAllocation/AssetAllocation")
);
const Videos = React.lazy(() => import("./Videos/Videos"));
const Ppts = React.lazy(() => import("./Ppts/Ppts"));
const Masterclass = React.lazy(() => import("./Masterclass/Masterclass"));
const FFFMasterClass = React.lazy(() =>
  import("./FFFMasterClass/FFFMasterClass")
);
const Rvccmodules = React.lazy(() => import("./Rvccmodules/Rvccmodules"));
const Template = React.lazy(() => import("./Templates/Template"));
const Truely = React.lazy(() => import("./Truely/Truely"));
const TruelyUpdated = React.lazy(() => import("./TruelyUpdated/TruelyUpdated"));
const Calculators = React.lazy(() => import("./Calculators/Calculators"));

const Detailedfinancialplan = React.lazy(() =>
  import("./Detailedfinancialplan/Detailedfinancialplan")
);
const Beyondclassroom = React.lazy(() =>
  import("./Beyondclassrooom/Beyondclassroom")
);
const Illusiongames = React.lazy(() => import("./Illusiongames/Illusiongames"));
const Financialmarketpuzzles = React.lazy(() =>
  import("./Financialmarketpuzzles/Financialmarketpuzzles")
);
const Gamesoftware = React.lazy(() => import("./Gamesoftware/Gamesoftware"));
const Boardgames = React.lazy(() => import("./Boardgames/Boardgames"));
const Leadershipgame = React.lazy(() =>
  import("./Leadershipgame/Leadershipgame")
);
const NominatedFunds = React.lazy(() =>
  import("./NominatedFunds/NominatedFunds")
);
const Physicalgames = React.lazy(() => import("./Physicalgames/Physicalgames"));
const Howtoconductiap = React.lazy(() =>
  import("./Hotoconductiap/Howtoconductiap")
);
const FFFConference = React.lazy(() => import("./FFFConference/FFFConference"));
const CreativityGame = React.lazy(() =>
  import("./CreativityGame/CreativityGame")
);
const AdvancedSelling = React.lazy(() =>
  import("./AdvancedSelling/AdvancedSelliing")
);
const AssetAllocationVideos = React.lazy(() =>
  import("./AssetAllocationVideos/AssetAllocationVideos")
);
const Signup = React.lazy(() => import("./Signup/Signup"));
const Others = React.lazy(() => import("./Others/Others"));
const UserProfile = React.lazy(() => import("./UserProfile/UserProfile"));

function Appnested() {
  const [isTimeout, setIsTimeout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 1200, //expire after 10 seconds
      onTimeout: () => {
        setIsTimeout(true);
        auth.onAuthStateChanged((user) => {
          if (user) {
            auth.signOut().then(() => {
              navigate("/volatilitydashboard");
              setIsTimeout(false);
              alert("Session Expired");
              window.location.reload();
              //   localStorage.removeItem("_userid");
            });
          }
        });
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);
  return (
    <>
      <Suspense
        style={{ display: "static" }}
        fallback={<div> Please Wait... </div>}
      >
        <Routes>
          <Route
            path="/"
            caseSensitive={false}
            element={
              <div className="holder">
                <Login />
              </div>
            }
          />
          <Route
            path="/:signup"
            caseSensitive={false}
            element={
              <div className="holder">
                <Signup />
              </div>
            }
          />
          <Route
            path="/signup"
            caseSensitive={false}
            element={
              <div className="holder">
                <Signup />
              </div>
            }
          />
          <Route
            path="/volatilitydashboard/signup"
            caseSensitive={false}
            element={
              <div className="holder">
                <Signup />
              </div>
            }
          />
          <Route
            path="/volatilitydashboard"
            caseSensitive={false}
            element={
              <div className="holder">
                <Login />
              </div>
            }
          />
          <Route
            path="/volatilitydashboard/:signup"
            caseSensitive={false}
            element={
              <div className="holder">
                <Signup />
              </div>
            }
          />
          <Route
            path="/vgdashboard"
            caseSensitive={false}
            element={
              <div className="holder">
                <Dashboard />
              </div>
            }
          />
          <Route
            path="/calculators"
            caseSensitive={false}
            element={
              <div className="holder">
                <Calculators />
              </div>
            }
          />
          <Route
            path="/excelcalculators"
            caseSensitive={false}
            element={
              <div className="holder">
                <ExcelCalculators />
              </div>
            }
          />
          <Route
            path="/monthlyreview"
            caseSensitive={false}
            element={
              <div className="holder">
                <Monthlyreview />
              </div>
            }
          />
          <Route
            path="/monthlyreview2"
            caseSensitive={false}
            element={
              <div className="holder">
                <Monthlyreview2 />
              </div>
            }
          />
          <Route
            path="/mutualfundspick"
            caseSensitive={false}
            element={
              <div className="holder">
                <Mutualfundspick />
              </div>
            }
          />
          <Route
            path="/others"
            caseSensitive={false}
            element={
              <div className="holder">
                <Others />
              </div>
            }
          />
          <Route
            path="/resources"
            caseSensitive={false}
            element={
              <div className="holder">
                <Resources />
              </div>
            }
          />
          <Route
            path="/mutualfundsanalysis"
            caseSensitive={false}
            element={
              <div className="holder">
                <Mutualfunds />
              </div>
            }
          />
          <Route
            path="/mutualfundsanalysisnew"
            caseSensitive={false}
            element={
              <div className="holder">
                <Mutualfundsnew />
              </div>
            }
          />
          <Route
            path="/assetallocation"
            caseSensitive={false}
            element={
              <div className="holder">
                <AssetAllocation />
              </div>
            }
          />
          <Route
            path="/volatilitygame2"
            caseSensitive={false}
            element={
              <div className="holder">
                <VolatilityGame2 />
              </div>
            }
          />
          <Route
            path="/riddles"
            caseSensitive={false}
            element={
              <div className="holder">
                <Riddles />
              </div>
            }
          />
          <Route
            path="/videos"
            caseSensitive={false}
            element={
              <div className="holder">
                <Videos />
              </div>
            }
          />
          <Route
            path="/ppts"
            caseSensitive={false}
            element={
              <div className="holder">
                <Ppts />
              </div>
            }
          />
          <Route
            path="/masterclass"
            caseSensitive={false}
            element={
              <div className="holder">
                <Masterclass />
              </div>
            }
          />
          <Route
            path="/fffmasterclass"
            caseSensitive={false}
            element={
              <div className="holder">
                <FFFMasterClass />
              </div>
            }
          />
          <Route
            path="/fffconference"
            caseSensitive={false}
            element={
              <div className="holder">
                <FFFConference />
              </div>
            }
          />
          <Route
            path="/creativitygame"
            caseSensitive={false}
            element={
              <div className="holder">
                <CreativityGame />
              </div>
            }
          />
          <Route
            path="/advancedselling"
            caseSensitive={false}
            element={
              <div className="holder">
                <AdvancedSelling />
              </div>
            }
          />
          <Route
            path="/assetallocationvideos"
            caseSensitive={false}
            element={
              <div className="holder">
                <AssetAllocationVideos />
              </div>
            }
          />
          <Route
            path="/rvccmodules"
            caseSensitive={false}
            element={
              <div className="holder">
                <Rvccmodules />
              </div>
            }
          />
          <Route
            path="/beyondclassroom"
            caseSensitive={false}
            element={
              <div className="holder">
                <Beyondclassroom />
              </div>
            }
          />
          <Route
            path="/howtoconductiap"
            caseSensitive={false}
            element={
              <div className="holder">
                <Howtoconductiap />
              </div>
            }
          />
          <Route
            path="/templates"
            caseSensitive={false}
            element={
              <div className="holder">
                <Template />
              </div>
            }
          />
          <Route
            path="/paidcourses"
            caseSensitive={false}
            element={
              <div className="holder">
                <PaidCourses />
              </div>
            }
          />
          <Route
            path="/extras"
            caseSensitive={false}
            element={
              <div className="holder">
                <Extras />
              </div>
            }
          />
          <Route
            path="/yourstruely"
            caseSensitive={false}
            element={
              <div className="holder">
                <TruelyUpdated />
              </div>
            }
          />
          <Route
            path="/yourstruelyupdated"
            caseSensitive={false}
            element={
              <div className="holder">
                <TruelyUpdated />
              </div>
            }
          />
          <Route
            path="/detailedfinancialplan"
            caseSensitive={false}
            element={
              <div className="holder">
                <Detailedfinancialplan />
              </div>
            }
          />
          <Route
            path="/illusiongames"
            caseSensitive={false}
            element={
              <div className="holder">
                <Illusiongames />
              </div>
            }
          />
          <Route
            path="/physicalgames"
            caseSensitive={false}
            element={
              <div className="holder">
                <Physicalgames />
              </div>
            }
          />
          <Route
            path="/financialmarketpuzzle"
            caseSensitive={false}
            element={
              <div className="holder">
                <Financialmarketpuzzles />
              </div>
            }
          />
          <Route
            path="/gamesoftware"
            caseSensitive={false}
            element={
              <div className="holder">
                <Gamesoftware />
              </div>
            }
          />
          <Route
            path="/boardandcardgames"
            caseSensitive={false}
            element={
              <div className="holder">
                <Boardgames />
              </div>
            }
          />
          <Route
            path="/leadershipgames"
            caseSensitive={false}
            element={
              <div className="holder">
                <Leadershipgame />
              </div>
            }
          />
          <Route
            path="/nominatedfunds"
            caseSensitive={false}
            element={
              <div className="holder">
                <NominatedFunds />
              </div>
            }
          />
          <Route
            path="/financialplanningtool/:vcid"
            caseSensitive={false}
            element={
              <div className="holder">
                <FinancialPlanningTool />
              </div>
            }
          />
          <Route
            path="/financialplanningtoolbackend"
            caseSensitive={false}
            element={
              <div className="holder">
                <FinancialplanningToolbackend />
              </div>
            }
          />
          <Route
            path="/fffarchive"
            caseSensitive={false}
            element={
              <div className="holder">
                <UserProfileEditor />
              </div>
            }
          />
          <Route
            path="/members"
            caseSensitive={false}
            element={
              <div className="holder">
                <Members />
              </div>
            }
          />
          <Route
            path="/howtoruninapple"
            caseSensitive={false}
            element={
              <div className="holder">
                <Howtoruninapple />
              </div>
            }
          />
          <Route
            path="/userProfile/:vcid"
            caseSensitive={false}
            element={
              <div className="holder">
                <UserProfile />
              </div>
            }
          />
          <Route
            path="/userProfileEditor"
            caseSensitive={false}
            element={
              <div className="holder">
                <UserProfileEditor />
              </div>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default Appnested;
